
jQuery(window).load(function(){
    // pre loading
    jQuery("[data-loading]").fadeOut(1000);
    // animate
    $('#js-logo-set-layer01').addClass('animated fadeInLeft');
    $('#js-logo-set-layer02').addClass('animated fadeIn');
    $('#js-logo-set-layer03').addClass('animated fadeInRight');
    $('#js-catch-1st').addClass('animated flipInX');
    $('#js-catch-2nd-set-layer01').addClass('animated fadeIn');
    $('#js-catch-2nd-set-layer02').addClass('animated fadeIn');
    $('#js-catch-2nd-set-layer03').addClass('animated fadeIn');
    $('#js-logo-2nd').addClass('animated fadeIn');
    $('#js-term').addClass('animated flipInX');
    $('#js-obj01').addClass('animated rotateIn');
    $('#js-obj02').addClass('animated rollIn');
});
